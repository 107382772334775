<template>
  <section
    class="m-providers white--bg"
    data-test="own-database"
  >
    <div class="m-providers__wrap container d-flex">
      <div class="m-providers__items d-flex flex-wrap">
        <a
          v-for="provider in providersArr"
          :key="provider.id"
          :href="
            provider.tariffless === false
              ? `/${currentCity?.url}/providers/${provider.slug}/`
              : `/providers/${provider.slug}/`
          "
          target="_blank"
          class="m-providers__item"
        >
          <img
            loading="lazy"
            :src="provider.logoT?.url"
            :alt="provider.name"
          >
        </a>
      </div>
      <div class="m-providers__body">
        <h2 class="m-providers__title headline-2">
          Поиск по собственной базе из
          {{ numberFormat(counts.housesCount) }} адресов
        </h2>
        <p class="m-providers__subtitle title-5 gray-dark--text">
          Работает бесплатно и не требует ввода конфиденциальной информации:
          квартиру и номер телефона указывать не нужно. Всего за пару секунд вы
          найдёте интернет-операторов, которые есть в вашем доме
        </p>
        <d-btn
          v-if="disabledPortal"
          data-test="find-providers"
          class="m-providers__btn title-4"
          color="primary"
          @click="showDialogAddress"
        >
          Найти провайдеров
        </d-btn>
      </div>
      <d-btn
        v-if="!disabledPortal"
        data-test="find-providers"
        class="m-providers__btn title-4"
        color="primary"
        @click="showDialogAddress"
      >
        Найти провайдеров
      </d-btn>
    </div>
  </section>
</template>

<script setup lang="ts">
import { numberFormat } from '~/helpers'
import { useMainStore } from '~/store/main/main.store'
import { useCities } from '~/store/cites/cities.store'

const props = defineProps({
  providers: {},
  allProviders: {},
  counts: {},
})

const cityStore = useCities()
const currentCity = computed(() => cityStore.getCity)
const mainStore = useMainStore()
const disabledPortal = computed(() => mainStore.disabledPortal)

const ctx = useNuxtApp()
const showDialogAddress = () => {
  ctx.$event('addressDialog', {
    redirect: true,
    label: 'latest-connected',
    title: 'Введите адрес и получите список доступных провайдеров и тарифов',
    goToPage: true,
  })
}

const providersArr = computed(() => {
  const arr = props.providers
    ?.sort((a, b) => !!a.tariffless - !!b.tariffless)
    ?.filter((el, i) => !(i > 5 && el.tariffless))
  if (arr?.length < 6) {
    const arrId = arr.map(el => el.id)
    return [
      ...arr,
      ...props.allProviders?.filter(el => !arrId.includes(el.id)),
    ].filter((e, i) => i < 6)
  }
  return arr
})
</script>

<style scoped lang="scss">
.m-providers {
  padding: 64px 0;
  @media (max-width: getBreakpoint(tablet)) {
    padding: 40px 0;
  }

  &__wrap {
    @media (max-width: getBreakpoint(desktop)) {
      flex-direction: column;
    }
  }

  &__items {
    margin-right: 64px;
    gap: 8px;
    @media (max-width: getBreakpoint(desktop)) {
      order: 2;
      margin-right: 0;
      margin-bottom: 24px;
    }
  }

  &__item {
    width: 184px;
    padding: 24px 16px;
    border-radius: 16px;
    border: 1px solid color(gray-darker);
    @media (max-width: getBreakpoint(desktop)) {
      width: unset;
      flex: 1 1 165px;
    }
    @media (max-width: getBreakpoint(tablet)) {
      padding: 16px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      flex: 1 1 120px;
    }
    img {
      display: flex;
      width: 112px;
      height: 28px;
      object-position: left;
      object-fit: contain;
    }
  }

  &__title {
    margin-bottom: 12px;
  }

  &__subtitle {
    margin-bottom: 24px;
  }
  &__btn {
    padding: 16px 40px;
    @media (max-width: getBreakpoint(desktop)) {
      order: 3;
      padding: 8px 40px;
      height: 44px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      height: 42px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      height: 40px;
    }
  }
}
</style>
